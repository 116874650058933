import { CustomerPreferences, Setting, SettingType } from '@/core/shared/model/Setting';
import { container } from 'tsyringe';
import { CustomerService } from '@/core/customer/services/CustomerService';
import store from '@/ui/store';
import { CustomerStoreMutations } from '@/ui/stores/CustomerStore';

export const upsertCustomerPreferencesUseCase = async (
  customerId: string,
  userId: string,
  customerPreferences: CustomerPreferences,
): Promise<void> => {
  const customerService = container.resolve<CustomerService>('CustomerService');
  await customerService.upsertCustomerPreferences(customerId, userId, customerPreferences);
  // since its just partial, build data around it in case its not yet in store
  // note SET_CUSTOMER_PREFERENCES_APP_TITLE_AND_APP_THEME will only update if current customer is updated else it won't do anything
  const prefToSave: Setting<CustomerPreferences> = {
    customerId,
    type: SettingType.CUSTOMER_PREFERENCES,
    content: customerPreferences,
  };
  store.commit(CustomerStoreMutations.SET_CUSTOMER_PREFERENCES_APP_TITLE_AND_APP_THEME, prefToSave);
};
