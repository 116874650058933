import { container } from 'tsyringe';
import type { Customer } from '@/core/customer/models/Customer';
import { CustomerStoreMutations } from '@/ui/stores/CustomerStore';
import store from '@/ui/store';
import { CustomerService } from '@/core/customer/services/CustomerService';

export const getAllCustomersUseCase = async (userId: string): Promise<Customer[]> => {
  const customerService = container.resolve<CustomerService>('CustomerService');
  const customers = await customerService.getAllCustomers(userId);
  store.commit(CustomerStoreMutations.SET_CUSTOMERS, customers);
  return customers;
};
