








import { Component, Prop, Vue } from 'vue-property-decorator';

export interface EventHandlers {
  click?: () => void;
}

@Component({
  name: 'ButtonWithIcon',
})
export default class ButtonWithIcon extends Vue {
  @Prop({ type: Object, default: () => new Object() }) eventHandlers!: EventHandlers;
  @Prop({ default: null }) icon?: string;

  emitClick() {
    this.$emit('click');
  }
}
