import { container } from 'tsyringe';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { CustomerService } from '@/core/customer/services/CustomerService';

export const customerLogoUploadUseCase = async (file: File, user: CurrentUser, customerId: string): Promise<void> => {
  const logoService = container.resolve<CustomerService>('CustomerService');

  const upload = await logoService.createLogoFileUploadLink(file, user, customerId);
  await logoService.uploadLogo(upload.uploadLink, file);
};
