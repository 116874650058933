






























import { Component, Prop, Vue } from 'vue-property-decorator';
import type { Customer } from '@/core/customer/models/Customer';
import type { User } from '@/core/admin/models/User';

@Component({
  name: 'LogoUpload',
})
export default class LogoUpload extends Vue {
  @Prop() createdCustomerId!: string;
  @Prop() logoToEdit!: string;
  protected areaIsHighlighted = false;
  private updatedCustomer = {} as Customer;
  selectedImage: string | ArrayBuffer | null | undefined = '';

  get logoPreview(): string {
    if (this.logoToEdit) {
      return this.logoToEdit;
    }
    return '';
  }
  get currentUser(): User {
    return this.$store.state.userStore.currentUser;
  }
  async selectFiles(files: File[]) {
    this.createImagePreview(files[0]);
    this.$emit('files-selected', files[0]);
    this.resetDragArea();
  }
  createImagePreview(file: File) {
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.addEventListener('load', (image) => {
        if (image) {
          this.selectedImage = image.target?.result;
        }
      });
    }
  }
  removeFile() {
    this.selectedImage = '';
    this.$emit('remove-file');
  }

  highlightDragArea() {
    this.areaIsHighlighted = true;
  }

  resetDragArea() {
    this.areaIsHighlighted = false;
  }
}
