var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"jls-data-table",attrs:{"headers":_vm.headers,"items":_vm.customers,"search":_vm.search,"page":_vm.page,"hide-default-footer":"","items-per-page":10},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.id",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.id)+" ")]}},{key:"header.isActive",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.login",fn:function(ref){
var item = ref.item;
return [(item)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.selectCustomer(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"accent"}},'v-icon',attrs,false),on),[_vm._v(" mdi-login ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('shared.common.tooltip-login')))])])],1):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"customer-icons-wrapper"},[_c('v-tooltip',{attrs:{"bottom":""}},[_c('ButtonWithIcon',{attrs:{"icon":'mdi-pencil-outline'},on:{"click":function($event){return _vm.setDialogState(item)}}}),_c('span',[_vm._v(_vm._s(_vm.$t('shared.common.tooltip-edit')))])],1)],1)]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [(item.isActive)?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(" mdi-check ")])],1):_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}},{key:"item.statistics",fn:function(ref){
var item = ref.item;
return [(item.statistics)?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(" mdi-check ")])],1):_c('v-btn',{attrs:{"icon":""}},[_vm._v(" - ")])]}}],null,true)}),_c('CustomerDialog',{attrs:{"show-dialog":_vm.showDialog,"customer":_vm.selectedCustomer,"customer-preferences":_vm.customerPreferences},on:{"close":_vm.setDialogState}}),_c('div',{staticClass:"pagination-wrapper"},[_c('v-pagination',{staticClass:"jls-data-table-footer",attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }