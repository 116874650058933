






























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isNotEmpty } from '@/ui/services/admin/InputVerification';
import { UpdateCustomerDTO } from '@/core/customer/DTO/UpdateCustomerDTO';
import getLogger from '@/shared/logger/logger';
import LogoUpload from '@/ui/components/atoms/LogoUpload.vue';
import type { Customer } from '@/core/customer/models/Customer';
import ModalWarning from '@/ui/components/molecules/modals/ModalWarning.vue';
import type { User } from '@/core/admin/models/User';
import { acceptedImageTypes } from '@/ui/util/customerAcceptedImageTypes';
import { AlertStoreActions, AlertType } from '@/ui/stores/AlertStore';
import { APP_THEME_TYPES, AppThemeType, CustomerPreferences } from '@/core/shared/model/Setting';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = getLogger('CustomerForm');

interface VForm extends HTMLFormElement {
  validate(): boolean;
  reset(): boolean;
}

@Component({
  name: 'CustomerForm',
  components: { LogoUpload, ModalWarning },
})
export default class CustomerForm extends Vue {
  @Prop({ default: false }) getFormData!: boolean;
  @Prop({ default: undefined }) customer!: Customer;
  @Prop({ default: () => ({}) }) customerPreferences: CustomerPreferences | undefined;
  private logoToEdit = '';
  private name = '';
  private isActive = false;
  private selectedLogo = {} as File;
  private showWarning = false;
  private customerValuesHaveBeenInitialized = false;
  private customerPreferredAppTitle = '';
  private customerPreferredAppTheme: AppThemeType | null = null;
  private APP_THEME_TYPES = APP_THEME_TYPES;

  mounted() {
    this.customer
      ? this.setInputValues(this.customer, this.customerPreferences)
      : this.skipActiveWatcherAndSetValuesAsInitialized();
  }

  @Watch('customer')
  onCustomerDataChange() {
    this.customer
      ? this.setInputValues(this.customer, this.customerPreferences)
      : this.skipActiveWatcherAndSetValuesAsInitialized();
  }

  @Watch('customerPreferences')
  onCustomerPreferencesChanged() {
    this.setCustomerPreferredInputValues(this.customerPreferences);
  }

  @Watch('isActive')
  onChange() {
    if (this.customer && this.customerValuesHaveBeenInitialized) {
      this.showWarning = true;
    }
  }

  @Watch('getFormData')
  onGetFormData(value: boolean) {
    if (this.isAllInputValid() && value) {
      const customer = {} as UpdateCustomerDTO;
      if (this.customer && this.customer.id) customer.id = this.customer.id;
      if (this.name && this.name !== this.customer?.name) customer.name = this.name;
      if (this.isActive !== this.customer?.isActive) customer.isActive = this.isActive;
      if (this.logoToEdit === '') customer.logo = this.logoToEdit; // remove logo
      if (Object.keys(customer).length > 0) {
        this.customer
          ? this.$emit('updateCustomerValidInput', customer, this.selectedLogo, this.getCustomerPreferences())
          : this.$emit('createCustomerValidInput', customer, this.selectedLogo, this.getCustomerPreferences());
      }
    } else {
      this.$emit('inputIsInvalid');
    }
  }

  getCustomerPreferences(): CustomerPreferences {
    return { appTitle: this.customerPreferredAppTitle, appTheme: this.customerPreferredAppTheme as AppThemeType };
  }

  get currentUser(): User {
    return this.$store.state.userStore.currentUser;
  }

  get isNotEmpty() {
    return isNotEmpty;
  }

  removeFile() {
    this.logoToEdit = '';
    this.selectedLogo = {} as File;
  }

  isAllInputValid() {
    const ref = this.$refs.form as VForm;
    return ref.validate();
  }

  clearInput() {
    this.resetShowWarningConditionValues();
    if (this.customer) {
      this.setInputValues(this.customer, this.customerPreferences);
    } else {
      this.name = '';
      this.isActive = false;
      const ref = this.$refs.logoUpload as LogoUpload;
      ref.removeFile();
    }
  }

  addFiles(file: File) {
    if (!acceptedImageTypes().includes(file.type)) {
      this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
        message: 'customer.customers.unsupported-mime-type',
        type: AlertType.WARNING,
      });
    }
    this.selectedLogo = file;
  }

  skipActiveWatcherAndSetValuesAsInitialized() {
    this.$nextTick(() => (this.customerValuesHaveBeenInitialized = true));
  }

  setInputValues(customer: Customer, preferences: CustomerPreferences | undefined): void {
    this.resetShowWarningConditionValues();
    this.name = customer.name;
    this.isActive = customer.isActive;
    this.logoToEdit = customer.logo || '';
    this.setCustomerPreferredInputValues(preferences);
    this.skipActiveWatcherAndSetValuesAsInitialized();
  }

  setCustomerPreferredInputValues(preferences: CustomerPreferences | undefined): void {
    this.customerPreferredAppTitle = preferences?.appTitle || '';
    this.customerPreferredAppTheme = preferences?.appTheme || null;
  }

  handleModalSave() {
    this.showWarning = false;
  }

  handleModalAbort() {
    this.resetShowWarningConditionValues();
    this.isActive = !this.isActive;
    this.skipActiveWatcherAndSetValuesAsInitialized();
  }

  resetShowWarningConditionValues() {
    this.showWarning = false;
    this.customerValuesHaveBeenInitialized = false;
  }
}
