












































































import { Mixins, Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { HeaderInfo } from '@/core/customer/models/CustomerDataTable';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { getAllCustomersUseCase } from '@/core/customer/usecases/getAllCustomersUseCase';
import store from '@/ui/store';
import { CustomerStoreMutations } from '@/ui/stores/CustomerStore';
import type { Customer } from '@/core/customer/models/Customer';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';
import { UserStoreMutations } from '@/ui/stores/UserStore';
import { CampaignStoreMutations } from '@/ui/stores/CampaignStore';
import { Loader } from '@/ui/util/decorators/loader';
import { ROUTE_CAMPAIGNS } from '@/ui/router/campaign.routes';
import CustomerDialog from '@/ui/components/organisms/dialogs/CustomerDialog.vue';
import ButtonWithIcon from '@/ui/components/atoms/buttons/ButtonWithIcon.vue';
import { AdminStoreMutations } from '@/ui/stores/AdminStore';
import { getCustomerPreferencesUseCase } from '@/core/customer/usecases/getCustomerPreferencesUseCase';
import { CustomerPreferences, Setting } from '@/core/shared/model/Setting';

@Component({
  name: 'customer-data-table',
  components: { CustomerDialog, ButtonWithIcon },
  computed: {},
})
export default class CustomerDataTable extends Mixins(NavigationMixin) {
  @Prop(String) readonly search: string | undefined;
  @Prop() currentUser!: CurrentUser;
  private page = 1;
  private pageCount = 0;
  protected showDialog = false;
  selectedCustomer = {} as Customer;
  customerPreferences = {} as Setting<CustomerPreferences>;

  async mounted() {
    await getAllCustomersUseCase(this.currentUser.id);
  }

  get customers() {
    return this.$store.state.customerStore.customers;
  }

  get headers(): Array<HeaderInfo> {
    return [
      { text: '', value: 'login', sortable: false },
      { text: this.$t('customer.customers.customer'), value: 'name', width: '30%' },
      { text: 'id', value: 'id' },
      { text: this.$t('customer.customers.status'), value: 'isActive' },
      { text: '', value: 'actions', sortable: false },
    ];
  }

  @Loader
  async selectCustomer(currentCustomer: Customer) {
    store.commit(CampaignStoreMutations.SET_ALL_AVAILABLE_CAMPAIGN_ZONES, undefined);
    store.commit(UserStoreMutations.UPDATE_CURRENT_USER_CUSTOMER_ID, currentCustomer.id);
    store.commit(CustomerStoreMutations.SET_CUSTOMER, currentCustomer);
    await getCustomerPreferencesUseCase(currentCustomer.id, this.currentUser.id);
    this.resetAdminZones();
    this.resetCampaignFilters();
    await this.$store.dispatch(CampaignStoreMutations.SET_CAMPAIGNS);
    this.goTo(ROUTE_CAMPAIGNS);
  }

  resetAdminZones() {
    store.commit(AdminStoreMutations.SET_ALL_ZONES, undefined);
  }

  resetCampaignFilters() {
    store.commit(CampaignStoreMutations.RESET_ALL_FILTERS);
  }

  async setDialogState(customer: Customer) {
    if (customer) {
      this.selectedCustomer = customer;
      this.customerPreferences = await getCustomerPreferencesUseCase(customer.id, this.currentUser.id, false);
    }
    this.showDialog = !this.showDialog;
  }
}
