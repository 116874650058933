
























import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomerDataTable from '@/ui/components/molecules/tables/CustomerDataTable.vue';
import type { CurrentUser } from '@/core/user/models/CurrentUser';

@Component({
  name: 'CustomerTab',
  components: { CustomerDataTable },
})
export default class CustomerTab extends Vue {
  @Prop() currentUser!: CurrentUser;

  private search = '';
}
