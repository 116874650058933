import { container } from 'tsyringe';
import { CustomerService } from '@/core/customer/services/CustomerService';
import store from '@/ui/store';
import { CustomerStoreMutations } from '@/ui/stores/CustomerStore';
import { CustomerPreferences, Setting } from '@/core/shared/model/Setting';

export const getCustomerPreferencesUseCase = async (
  customerId: string,
  userId: string,
  commitToStore = true,
): Promise<Setting<CustomerPreferences>> => {
  const customerService = container.resolve<CustomerService>('CustomerService');
  const customerPreferences: Setting<CustomerPreferences> = await customerService.getCustomerPreferences(
    customerId,
    userId,
  );
  if (commitToStore) {
    store.commit(CustomerStoreMutations.SET_CUSTOMER_PREFERENCES, customerPreferences);
  }
  return customerPreferences;
};
