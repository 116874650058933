











































import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonWithText from '@/ui/components/atoms/buttons/ButtonWithText.vue';

@Component({
  name: 'ModalWarning',
  components: { ButtonWithText },
})
export default class ModalWarning extends Vue {
  @Prop() iconSrc!: string;
  @Prop() alertText!: string;

  get icon() {
    return this.iconSrc ? this.iconSrc : require('@/ui/assets/icons/delete.svg');
  }

  get text() {
    return this.alertText ? this.alertText : 'Admin.mandatory-labels.warning-body';
  }

  emitAbortClicked() {
    this.$emit('abortClicked');
  }

  emitSaveClicked() {
    this.$emit('saveClicked');
  }
}
