











































import { Vue, Component, Prop } from 'vue-property-decorator';
import CustomerForm from '@/ui/components/organisms/forms/CustomerForm.vue';
import type { User } from '@/core/admin/models/User';
import DialogTitle from '@/ui/components/atoms/textItems/DialogTitle.vue';
import { AlertStoreActions, AlertType } from '@/ui/stores/AlertStore';
import { createCustomerUseCase } from '@/core/customer/usecases/createCustomerUseCase';
import { CreateCustomerDTO } from '@/core/customer/DTO/CreateCustomerDTO';
import { customerLogoUploadUseCase } from '@/core/customer/usecases/customerLogoUploadUseCase';
import { getLogger } from 'loglevel';
import type { Customer } from '@/core/customer/models/Customer';
import { UpdateCustomerDTO } from '@/core/customer/DTO/UpdateCustomerDTO';
import { updateCustomerUseCase } from '@/core/customer/usecases/updateCustomerUsecase';
import { getAllCustomersUseCase } from '@/core/customer/usecases/getAllCustomersUseCase';
import { acceptedImageTypes } from '@/ui/util/customerAcceptedImageTypes';
import ButtonWithText from '@/ui/components/atoms/buttons/ButtonWithText.vue';
import { CustomerPreferences, Setting } from '@/core/shared/model/Setting';
import { upsertCustomerPreferencesUseCase } from '@/core/customer/usecases/upsertCustomerPreferencesUseCase';

const LOG = getLogger('NewCustomerDialog');

@Component({
  name: 'CustomerDialog',
  components: { ButtonWithText, DialogTitle, CustomerForm },
})
export default class CustomerDialog extends Vue {
  @Prop({ default: null }) customer: Customer | undefined;
  @Prop({ type: Boolean }) showDialog!: boolean;
  @Prop({ default: () => ({}) }) customerPreferences: Setting<CustomerPreferences> | undefined;
  private triggerGetCustomerFormData = false;
  private isLoading = false;
  dialogTitle = '';
  dialogSuccessButtonText = '';

  get preferences() {
    return this.customerPreferences?.content;
  }

  mounted() {
    if (this.customer) {
      this.dialogTitle = 'customer.edit-customer.title';
      this.dialogSuccessButtonText = 'customer.edit-customer.confirm';
    } else {
      this.dialogTitle = 'customer.create-customer.title';
      this.dialogSuccessButtonText = 'customer.create-customer.create';
    }
  }

  get currentUser(): User {
    return this.$store.state.userStore.currentUser;
  }

  async refresh() {
    await getAllCustomersUseCase(this.currentUser.id);
  }

  createCustomerValidInput(
    customerToCreate: CreateCustomerDTO,
    selectedLogo: File,
    customerPreferences: CustomerPreferences,
  ) {
    this.isLoading = true;
    createCustomerUseCase(this.currentUser.id, customerToCreate)
      .then((createdCustomer) => this.afterCustomerUpdated(createdCustomer, selectedLogo, customerPreferences))
      .catch(() => this.triggerErrorAlert())
      .finally(() => this.resetInitialFlagState());
  }

  updateCustomerValidInput(
    customerToUpdate: UpdateCustomerDTO,
    selectedLogo: File,
    customerPreferences: CustomerPreferences,
  ) {
    this.isLoading = true;
    updateCustomerUseCase(this.currentUser.id, customerToUpdate)
      .then(() => this.afterCustomerUpdated(this.customer as Customer, selectedLogo, customerPreferences))
      .catch(() => this.triggerErrorAlert())
      .finally(() => this.resetInitialFlagState());
  }

  afterCustomerUpdated(createdCustomer: Customer, selectedLogo: File, customerPreferences: CustomerPreferences) {
    this.closeDialog();
    if (selectedLogo.name) {
      if (acceptedImageTypes().includes(selectedLogo.type)) {
        try {
          customerLogoUploadUseCase(selectedLogo, this.currentUser, createdCustomer.id);
        } catch (e) {
          LOG.error(e);
        }
      } else {
        this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
          message: 'customer.customers.image-cannot-be-saved',
          type: AlertType.ERROR,
        });
      }
    }
    try {
      upsertCustomerPreferencesUseCase(createdCustomer.id, this.currentUser.id, customerPreferences);
    } catch (e) {
      LOG.error(e);
    }
    const successMessage = this.customer
      ? 'customer.edit-customer.confirm-message'
      : 'customer.create-customer.customer-created';
    this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
      message: successMessage,
      type: AlertType.SUCCESS,
    });
    this.$emit('created');
    this.refresh();
  }

  triggerErrorAlert() {
    const errorMessage = this.customer ? 'customer.edit-customer.error-alert' : 'customer.create-customer.error-alert';
    this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
      message: errorMessage,
      type: AlertType.ERROR,
    });
  }

  resetInitialFlagState() {
    this.triggerGetCustomerFormData = false;
    this.isLoading = false;
  }

  handleInvalidInput() {
    this.triggerGetCustomerFormData = false;
  }

  closeDialog() {
    this.$emit('close');
    this.clearInput();
  }

  clearInput() {
    const ref = this.$refs.customerForm as CustomerForm;
    ref.clearInput();
  }

  getCustomerFormData() {
    this.triggerGetCustomerFormData = true;
  }
}
