















import { Component, Mixins } from 'vue-property-decorator';
import ButtonWithTextAndIcon from '@/ui/components/atoms/buttons/ButtonWithTextAndIcon.vue';
import CustomerDialog from '@/ui/components/organisms/dialogs/CustomerDialog.vue';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';

@Component({
  name: 'CustomerViewTitle',
  components: { CustomerDialog, ButtonWithTextAndIcon },
})
export default class CustomerViewTitle extends Mixins(NavigationMixin) {
  protected showDialog = false;

  setDialogState() {
    this.showDialog = !this.showDialog;
  }
}
