














import { Component, Vue } from 'vue-property-decorator';
import AppHeader from '@/ui/components/organisms/AppHeader.vue';
import CustomerTab from '@/ui/components/organisms/tabs/CustomerTab.vue';
import CustomerViewTitle from '@/ui/components/molecules/titles/CustomerViewTitle.vue';

@Component({
  name: 'CustomerView',
  components: {
    CustomerViewTitle,
    CustomerTab,
    AppHeader,
  },
})
export default class CustomerView extends Vue {
  private showSuccessAlert = false;
  private alertMessage = '';

  get currentUser() {
    return this.$store.state.userStore.currentUser;
  }
}
